import React, { useMemo, useState } from "react";
import { EditFormModalButton } from "@shared/modal";
import { DeleteButton } from "@shared/form_fields";
import { TableEmpty, Column } from "@shared/tables/table";
import { AutoTable } from "@shared/tables/auto_table";
import Flash from "@shared/flash";
import { Card } from "@shared/card";
import { useQueryParams } from "@shared/filter_sort_paginate";
import { useAjax } from "@shared/ajax";
import { Search } from "@shared/search";
import AudioManager from "@shared/audio-manager";
import { PlayCircleIcon, StopCircleIcon } from "@heroicons/react/24/solid";
import { DownloadFilledIcon } from "@shared/icons/momentum";

export default function PromptShow({ prompt: data }) {
  const [params, setParams] = useQueryParams({
    sort_by: { default: "name" },
    sort_order: { default: "asc" },
    page: { type: "number", default: 1 },
    per_page: { type: "number", default: 50 },
    search: { type: "string", default: "" },
  });

  const { sort_by, sort_order, page, per_page, search } = params;

  const ajax = useAjax(data);

  const prompt = ajax.data;

  const getAttachments = () => {
    let attachments = prompt.attachments?.map((a) => ({
      name: a.filename,
      contentType: a.content_type,
      locale: prompt.attachmentlocales.find(
        (al) =>
          al.attachment_text === null &&
          al.file_name?.split(".")[0] === a.filename
      )?.locale,
      url: a.url,
    }));

    return attachments
      ? attachments.concat(
          prompt.attachmentlocales
            ?.filter((a) => a.attachment_text !== null)
            ?.map((a) => ({
              name: a.attachment_text,
              contentType: "Text",
              locale: a.locale,
            }))
        )
      : prompt.attachmentlocales
          ?.filter((a) => a.attachment_text !== null)
          ?.map((a) => ({
            name: a.attachment_text,
            contentType: "Text",
            locale: a.locale,
            id: a.id,
          }));
  };

  const attachments = getAttachments();

  const filteredAttachments = useMemo(() => {
    if (!search) return attachments;
    return attachments.filter((attachment) =>
      attachment.locale.toLowerCase().includes(search.toLowerCase())
    );
  }, [ajax.data, search]);

  return (
    <>
      <Flash />

      <div className="flex justify-between items-center">
        <h1 className="font-semibold text-2xl text-slate-700">
          Prompts Attachments
        </h1>
      </div>

      <Card className="mt-3 pb-6">
        <div id="attachments" className="flex justify-between p-8">
          <div className="flex grow">
            <Search
              value={search}
              items={attachments?.map((a) => a.locale)}
              onChange={(search) => setParams({ search })}
              className="z-10 w-4/5 md:w-3/5"
            />
          </div>

          <div>
            <EditFormModalButton
              instance={prompt}
              className="rounded-md py-1 pr-3 font-medium text-primary-700 border-primary-700 hover:bg-slate-50 border text-nowrap pl-3 w-30"
              buttonText="Add Attachment"
              title="Add Attachment"
              ajax={true}
              onSubmit={ajax.refresh}
              closeOnSubmit={true}
              customBtn={
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="w-5 h-5 mr-1 inline-block align-text-top"
                  >
                    <path d="M10.75 4.75a.75.75 0 0 0-1.5 0v4.5h-4.5a.75.75 0 0 0 0 1.5h4.5v4.5a.75.75 0 0 0 1.5 0v-4.5h4.5a.75.75 0 0 0 0-1.5h-4.5v-4.5Z" />
                  </svg>
                  Add Attachment
                </span>
              }
              props={{
                localCodes: data.localCodes,
                entity_id: prompt.entity_id,
                prompt: prompt,
                attachments: attachments,
              }}
            />
          </div>
        </div>
      </Card>

      <Card className="mt-3 pb-6">
        <AutoTable
          data={filteredAttachments}
          loading={ajax.loading}
          sortBy={sort_by}
          sortOrder={sort_order}
          onSortChanged={(sortBy, sortOrder) => {
            setParams({ sort_by: sortBy, sort_order: sortOrder });
          }}
          currentPage={page}
          pageSize={per_page}
          onPageChanged={(page) => setParams({ page })}
        >
          <TableEmpty>
            <p className="p-6 text-gray-900">
              <i>No attachments created yet</i>
            </p>
          </TableEmpty>
          <Column title="Locale" field="locale">
            {(attachment) =>
              attachment.contentType === "Text" ? (
                <span instance={prompt}>{attachment.locale}</span>
              ) : (
                <AudioControl
                  key={"Attachment_" + prompt.id}
                  prompt={prompt}
                  attachment={attachment}
                />
              )
            }
          </Column>
          <Column title="Type" field="contentType">
            {(attachment) => (
              <span instance={prompt}>{attachment.contentType}</span>
            )}
          </Column>
          <Column sortable={false} style={{ width: "1%" }}>
            {(p) => (
              <div className="flex gap-3 items-center">
                <EditFormModalButton
                  instance={prompt}
                  className="w-28"
                  buttonText="Edit"
                  title="Edit Attachment"
                  ajax={true}
                  onSubmit={ajax.refresh}
                  closeOnSubmit={true}
                  props={{
                    localCodes: data.localCodes,
                    entity_id: prompt.entity_id,
                    prompt: prompt,
                    isUpdate: {
                      rowId: p.locale,
                    },
                  }}
                />
                <DeleteButton
                  instance={prompt}
                  className="w-24"
                  ajax={true}
                  onDelete={ajax.refresh}
                  appendToAction={`/attachments/${prompt?.attachmentlocales?.find((a) => a.locale === p.locale)?.id}`}
                />
              </div>
            )}
          </Column>
        </AutoTable>
      </Card>
    </>
  );
}

const AudioControl = ({ prompt, attachment }) => {
  const [isPlaying, setIsPlaying] = useState(false);

  const play = (url, id, play) => {
    setIsPlaying(play);
    AudioManager.playAudio(url, prompt.id + "-" + id);
  };

  return (
    <div className="flex flex-row gap-2">
      <span>{attachment.locale}</span>

      {attachment.name ? (
        <>
          <div
            className={`rounded border ${attachment.name ? "border-primary-600" : "border-gray-500"} w-8 text-sm px-1 inline-flex gap-2 justify-center items-center`}
          >
            {!isPlaying ? (
              <PlayCircleIcon
                key={prompt.id}
                onClick={() => {
                  if (attachment.name) {
                    play(attachment.url, "attachments", true);
                  }
                }}
                className="fill-primary-600 inline-block h-6 cursor-pointer"
              />
            ) : (
              <StopCircleIcon
                key={prompt.id}
                onClick={() => {
                  if (attachment.name) {
                    play(attachment.url, "attachments", false);
                  }
                }}
                className="fill-orange-600 inline-block h-6 cursor-pointer"
              />
            )}
          </div>
          <div
            className={`rounded border ${attachment.name ? "border-primary-600" : "border-gray-500"} w-8 text-sm px-1 inline-flex gap-2 justify-center items-center`}
          >
            <a target="_blank" href={attachment.url}>
              <DownloadFilledIcon
                key={prompt.id}
                className="fill-primary-600 inline-block h-5 cursor-pointer"
              />
            </a>
          </div>
        </>
      ) : (
        <i className="text-gray-500 p-1">None</i>
      )}
    </div>
  );
};
