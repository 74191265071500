import React, { useCallback, useState, useRef } from "react";
import { getNestedValueFromString, labelize, useEffectAfterMount, useRailsContext } from "./utils";
import { twMerge } from 'tailwind-merge'
import { Switch } from "@headlessui/react";

export function Form({
  children,
  show = true,
  action = "show",
  formAction = null,
  method = null,
  model = null,
  controller = null,
  instance = {} as any,
  props = {},
  onSubmit = null,
  readOnly = null,
  ajax = false,
  nestedInOrganization = true,
  template = null,
  handleCancel = () => {},
  disableOnSubmit = true
}) {

  const railsContext = useRailsContext();

  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [result, setResult] = useState(null);
  const [resetCount, setResetCount] = useState(0);

  const reset = () => {
    setResetCount(prevCount => prevCount + 1);
    setLoading(false);
    setError(null);
    setSubmitted(false);
    setResult(null);
    setDisabled(false);
  }
  const enable = () => setDisabled(false);
  const disable = () => setDisabled(true);

  if (!model) {
    model = instance.__model_name || railsContext.model_name;
  }

  // We disable the form if it's loading or submitted to prevent double submissions
  // To re-enable the form after submit, you can call the `reset` function
  if (readOnly === null) {
    readOnly = (action === "show") || disabled || loading || (disableOnSubmit && submitted);
  }

  if (!method) {
    method = "post";
    if (action === "edit" || action === "show") {
      method = "put";
    }
  }

  if (!controller) {
    controller = instance.__controller_name || railsContext.controller_name;
  }

  if (!formAction) {
    formAction = `/${controller}`
    if (action === "edit" || action === "show") {
      formAction += `/${instance.id}`
    }

    if (railsContext.current_organization?.id && nestedInOrganization) {
      formAction = `/${railsContext.current_organization.id}${formAction}`
    }
  }

  const formRef = useRef(null);

  const handleSubmit = useCallback(async (event) => {
    event.preventDefault();
    setLoading(true);

    if (!ajax) {
      // If not using AJAX, submit the form normally
      if (onSubmit) {
        onSubmit(event);
      }

      // We need to submit the form manually via js otherwise it doesn't work correctly
      // due to setLoading causing a re-render and the form being submitted before the state is updated
      formRef.current.submit();
      return;
    }

    const formData : any = new FormData(event.target);
    // const jsonBody = {};
    // // Formats the form data as JSON objects per Rails naming convention
    // // e.g., "user[name]" becomes `{ user: { name: "John" } }`
    // for (let [key, value] of formData.entries()) {
    //   // Split the key based on Rails naming convention (e.g., "user[name]")
    //   const regex = /^([^\[]+)|\[([^\]]+)\]/g;
    //   // const match = key.matchAll(/(\w+)(\[(\w*)\])+/g);
    //   let match = regex.exec(key);
    //   let parentKey = match[0];
    //   let childKeys = [];
    //   while ((match = regex.exec(key)) !== null) {
    //     childKeys.push(match[2]);
    //   }

    //   if (value instanceof File) {
    //     value = await fileToBase64(value);  // Converts file to Base64
    //   }

    //   if (childKeys.length > 0) {
    //     setNestedValue(jsonBody, childKeys, value);
    //   } else {
    //     // Handle keys that don't use the Rails naming convention (e.g., just "name")
    //     jsonBody[parentKey] = value;
    //   }
    // }

    const method = formData.get('_method') || 'post';

    try {
      const response = await fetch(event.target.action, {
        method: method,
        headers: {
          // "Content-Type": "application/json",
          "Accept": "application/json",
          "X-CSRF-Token": railsContext.csrf_token
        },
        body: formData //JSON.stringify(jsonBody)
      });
      const result = await response.json();

      if (!response.ok) {
        console.warn(`Error submitting via ajax: ${response.status} - ${response.statusText}`, result);
        setLoading(false);
        setError(result);
        setSubmitted(true);
        return;
      }

      setLoading(false);
      setSubmitted(true);
      setResult(result);
      if (onSubmit) {
        onSubmit(result);
      }

    } catch (error) {
      console.error("Error submitting via ajax", error);
      setLoading(false);
      setError(error);
      setSubmitted(true);
    }
  }, [formRef, ajax, onSubmit]);

  return (
    <form ref={formRef} action={formAction} method="POST" onSubmit={handleSubmit}>
      <input name="_method" type="hidden" value={method} />
      <CsrfToken />

      <FormContext.Provider value={{ model, action, readOnly, instance, shown: show, loading, submitted, error, enable, disable, result, close: handleCancel, reset, resetCount }}>
        {children}
      </FormContext.Provider>
    </form>
  )

}

export function FormField({field, type = "text", value = null, children = null, label = null, readOnly = false, required = false, className = ""}) {
  const formContext = useFormContext();

  if (label === null) {
    label = labelize(field);
  }

  readOnly = readOnly || formContext.readOnly;

  return (
    <div className={twMerge("my-5", className)}>
      <label htmlFor={field} className="block">{label}</label>
        {children?.length ? children : <Input field={field} instance={formContext.instance} type={type} value={value} readOnly={readOnly} required={required} />}
    </div>
  )
}

export function HiddenField({field, value, ...props}) {
  const formContext = useFormContext();
  return <Input field={field} value={value} instance={formContext.instance} type="hidden" {...props} />
}

export function CheckboxField({field, label = null, fieldKey = null, asArray = false, readOnly = false, onChange = null, className = ""}) {
  const formContext = useFormContext();
  const railsContext = useRailsContext();

  let nameKey = `[${field}]`;
  if (field.includes(".")) {
    // Supports nested fields like "details.name"
    nameKey = getNestedKeyNameFromString(field);
  }

  let name = `${formContext.instance.__model_name || formContext.model || railsContext.model_name}${nameKey}`; // like "organization[name]"
  if (fieldKey !== null) {
    name = `${name}[${fieldKey}]`;
  }
  if (asArray) {
    name = `${name}[]`;
  }

  readOnly = readOnly || formContext.readOnly;

  let value = formContext.instance[field];
  if (field.includes(".")) {
    value = getNestedValueFromString(formContext.instance, field) === true;
  }

  const [checked, setChecked] = React.useState(value);

  // Update the checked state when the form is reset
  useEffectAfterMount(() => {
    setChecked(value);
  }, [formContext.resetCount, value]);

  if (label === null) {
    label = labelize(field);
  }

  readOnly = readOnly || formContext.readOnly;
  // const name = `${railsContext.model_name}[${field}]`; // like "organization[name]"

  const handleChange = (e) => {
    setChecked(e.target.checked);
    if (onChange) {
      onChange(e);
    }
  }

  return (
    <div className={twMerge("my-5 flex items-center", className)}>
      {/* hidden value ensures this submits even if unchecked (mimics how the Rails tag helper does it) */}
      <input name={name} type="hidden" value="0" autoComplete="off" />
      {/* the actual checkbox */}
      <input id={field} name={name} type="checkbox" value="1"
        disabled={readOnly} className={`${!readOnly && "cursor-pointer"}`}
        checked={checked}
        onChange={handleChange}
      />
      <label htmlFor={field} className={`pl-2 inline-block select-none ${!readOnly && "cursor-pointer"}`}
        onClick={(e) => {
          e.preventDefault();
          !readOnly && setChecked(!checked);
        }
      }>
        {label}
      </label>
    </div>
  )
}

export function Checkbox({name, value = "1", checked = false, onChange, label, readOnly = false}) {
  const formContext = useFormContext();
  readOnly = readOnly || formContext.readOnly;

  return (
    <div className="my-5">
      {/* hidden value ensures this submits even if unchecked (mimics how the Rails tag helper does it) */}
      <input name={name} type="hidden" value="0" autoComplete="off" />
      {/* the actual checkbox */}
      <input id={name} name={name} type="checkbox" value={value}
        disabled={readOnly} className={`${!readOnly && "cursor-pointer"}`}
        checked={checked}
        onChange={onChange}
      />
      <label htmlFor={name} className={`pl-2 inline-block select-none ${readOnly ? "disabled" : "cursor-pointer"}`}>
        {label}
      </label>
    </div>
  )
}

export function ToggleField({field, fieldKey = null, asArray = false, label = null, readOnly = false, onChange = null}) {
  const formContext = useFormContext();
  const railsContext = useRailsContext();

  let value = formContext.instance[field];
  if (field.includes(".")) {
    value = getNestedValueFromString(formContext.instance, field);
  }

  const [checked, setChecked] = React.useState(value === true);

  // Update the checked state when the form is reset
  useEffectAfterMount(() => {
    setChecked(value === true);
  }, [formContext.resetCount, value]);

  if (label === null) {
    label = labelize(field);
  }

  let nameKey = `[${field}]`;
  if (field.includes(".")) {
    // Supports nested fields like "details.name"
    nameKey = getNestedKeyNameFromString(field);
  }

  readOnly = readOnly || formContext.readOnly;
  // const name = `${railsContext.model_name}${nameKey}`; // like "organization[name]"

  let name = `${formContext.instance.__model_name || formContext.model || railsContext.model_name}${nameKey}`; // like "organization[name]"
  if (fieldKey !== null) {
    name = `${name}[${fieldKey}]`;
  }
  if (asArray) {
    name = `${name}[]`;
  }

  const handleChange = (checked) => {
    setChecked(checked);
    if (onChange) {
      onChange(checked);
    }
  }

  return (
    <div className="my-5 flex items-center">
      {/* hidden value ensures this submits even if unchecked (mimics how the Rails tag helper does it) */}
      <input name={name} type="hidden" value="0" autoComplete="off" disabled={checked} />
      {/* the actual toggle-checkbox */}
      <Switch id={field} name={name} value="1"
        checked={checked}
        onChange={handleChange}
        disabled={readOnly}
        className={`${checked ? 'bg-primary-600' : 'bg-gray-600'}
          relative inline-flex h-[20px] w-[42px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75`}
      >
        <span className="sr-only">{field} enabled</span>
        <span
          aria-hidden="true"
          className={`${checked ? 'translate-x-[18px]' : 'translate-x-0'}
            pointer-events-none inline-block h-[16px] w-[20px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
        />
      </Switch>

      <label htmlFor={field} className={`pl-2 inline-block select-none align-text-bottom ${!readOnly ? "cursor-pointer" : "disabled"}`}
        onClick={(e) => {
          e.preventDefault();
          !readOnly && setChecked(!checked);
        }
      }>
        {label}
      </label>
    </div>
  )
}

// Get the intended value from form_context.instance, accounting for however many levels it is nested
function parseField(field: string, instance: any): string {
  const splitField = field.split(".")
  let defaultSelectValue = instance
  for (const level of splitField) {
    if (!defaultSelectValue) return null
    defaultSelectValue = defaultSelectValue?.[level]
  }
  return defaultSelectValue
}

export function SelectField({field, options, label = null, fieldKey = null, asArray = false, readOnly = false, defaultLabel = null}) {
  const formContext = useFormContext();
  const railsContext = useRailsContext();

  if (label === null) {
    label = <span className="capitalize">{field.replace(/_id$/, "").replace(/_/g, " ")}</span>;
  }

  readOnly = readOnly || formContext.readOnly;
  // const name = `${railsContext.model_name}[${field}]`; // like "organization[name]"

  let nameKey = `[${field}]`;
  if (field.includes(".")) {
    // Supports nested fields like "details.name"
    nameKey = getNestedKeyNameFromString(field);
  }

  let name = `${formContext.instance.__model_name || formContext.model || railsContext.model_name}${nameKey}`; // like "organization[name]"
  if (fieldKey !== null) {
    name = `${name}[${fieldKey}]`;
  }
  if (asArray) {
    name = `${name}[]`;
  }

  // convert options to {value, label} format if it's not already
  let selectOptions = options || [];
  if (options?.length && typeof options[0] === "string") {
    // If passed an array of strings, convert to {value, label} format using the string as both value and label
    selectOptions = options.map(option => ({value: option, label: option}));
  } else if (options?.length && typeof options[0] === "object" && options[0].id) {
    // If passed an array of objects with an id, convert to {value, label} format using the id as value and name as label
    // Mainly used to convert Rails model instances to select options
    selectOptions = options.map(option => ({value: option.id, label: option.label || option.name || option.id}));
  }
  const defaultSelectValue = parseField(field, formContext.instance)

  return (
    <div className="my-5">
      <label htmlFor={field} className="block">{label}</label>
      <select id={field} name={name}
        disabled={readOnly} className="block shadow rounded-md border border-gray-200 outline-none px-3 py-2 mt-2 w-full disabled:bg-gray-50 disabled:opacity-60"
        defaultValue={defaultSelectValue}
      >
        <option value="">{defaultLabel || "- None -"}</option>
        {selectOptions.map((option, i) => (
          <option key={i} value={option.value}>{option.label}</option>
        ))}
      </select>
    </div>
  )
}

// private
function Input({type = "text", value = null, field, fieldKey = null, asArray = false, instance = {} as any, readOnly = false, required = false}) {
  const railsContext = useRailsContext();
  const formContext = useFormContext();

  let nameKey = `[${field}]`;
  if (field.includes(".")) {
    // Supports nested fields like "details.name"
    nameKey = getNestedKeyNameFromString(field);
  }

  let name = `${instance.__model_name || formContext.model || railsContext.model_name}${nameKey}`; // like "organization[name]"
  if (fieldKey !== null) {
    name = `${name}[${fieldKey}]`;
  }
  if (asArray) {
    name = `${name}[]`;
  }

  readOnly = readOnly || formContext.readOnly;

  if (!value && field.includes(".")) {
    value = getNestedValueFromString(instance, field);
  }

  if (value === null) {
    value = instance[field] !== null ? instance[field] : ""
  }

  const [stagedValue, setStagedValue] = useState(value);

  // Update the staged value when the form is reset
  useEffectAfterMount(() => {
    setStagedValue(value);
  }, [formContext.resetCount, value, instance[field]]);

  return (
    <input id={field} name={name} type={type} required={required}
      disabled={readOnly} className="block shadow rounded-md border border-gray-200 outline-none px-3 py-2 mt-2 w-full disabled:bg-gray-50 disabled:opacity-60"
      value={stagedValue} onChange={(e) => setStagedValue(e.target.value)}
    />
  )
}

export const Button = (props) => <button {...props} className={twMerge("rounded-lg py-3 px-5 font-medium bg-blue-600 text-white text-nowrap", props.className || "")}></button>
export const OutlineButton = (props) => <button {...props} className={twMerge("rounded-md py-1 pl-2 pr-3 font-medium text-primary-700 border-primary-700 hover:bg-slate-50 border text-nowrap", props.className || "")}></button>
export const SecondaryButton = (props) => <button {...props} className={twMerge("rounded-lg py-3 px-5 font-medium bg-gray-100 text-black text-nowrap", props.className || "")}></button>
export const SecondaryOutlineButton = (props) => <button {...props} className={twMerge("rounded-md py-1 pl-2 pr-3 hover:bg-slate-50 font-medium text-slate-600 border-slate-300 border text-sm text-nowrap", props.className || "")}></button>

export const DeleteButton = ({label = null, instance, className, dontConfirm = false, ajax = false, onDelete, nestedInOrganization = true, modelLabel = null, instanceName = null, appendToAction = null}) => {
  const railsContext = useRailsContext();

  let formAction = appendToAction ? `/${instance.__controller_name}/${instance.id}${appendToAction}` : `/${instance.__controller_name}/${instance.id}`;
  if (railsContext.current_organization && nestedInOrganization) {
    formAction = `/${railsContext.current_organization.id}${formAction}`;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (dontConfirm || confirm(`Are you sure you want to delete ${modelLabel || instance.__model_name.replace(/_/g, " ")}: "${instanceName || instance.name || instance.id}"?`)) {
      if (!ajax) {
        e.target.submit();
        return;
      }

      const response = await fetch(formAction, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "X-CSRF-Token": railsContext.csrf_token,
          "X-Requested-With": "XMLHttpRequest",
          "Cache-Control": "no-cache"
        }
      })

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      onDelete && onDelete();
    }
  }

  return (
    <form action={formAction} method="post" onSubmit={handleSubmit}>
      <input type="hidden" name="_method" value="delete" />
      <CsrfToken />

      <SecondaryOutlineButton type="submit" formAction="DELETE" className={className}>
        {label ? label :
          <div className="flex justify-between items-center pl-2"> {/* flex to align icon and text */}
            <span>Delete</span>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 inline-block align-text-top opacity-75">
              <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
            </svg>
          </div>
        }
      </SecondaryOutlineButton>
    </form>
  )
}

function getNestedKeyNameFromString(field) {
  const keys = field?.split(".") || [""]
  return keys.reduce((acc, key) => `${acc}[${key}]`, "");
}

export interface FormContextType {
  model: string,
  action: string,
  readOnly: boolean,
  instance?: any,
  shown: boolean,
  loading: boolean,
  submitted: boolean,
  error: any,
  result: any,
  enable: () => void,
  disable: () => void,
  close: () => void,
  resetCount?: number,
  reset: () => void
}
export const FormContext = React.createContext<FormContextType>({
  model: "",
  action: "",
  readOnly: false,
  shown: false,
  loading: false,
  submitted: false,
  error: null,
  result: null,
  enable: () => {},
  disable: () => {},
  close: () => {},
  resetCount: 0,
  reset: () => {}
});
export const useFormContext = () => React.useContext(FormContext) as FormContextType;

export const CsrfToken = () => {
  const railsContext = useRailsContext();
  return <input type="hidden" name="authenticity_token" value={railsContext.csrf_token} />
}
