import React from "react";
import { FormField } from "@shared/form_fields";

export default function LookupTableForm({ isEdit }) {
  return (
    <>
      <FormField field="name" />
      <FormField readOnly={isEdit} field="lookup_key_field" />
    </>
  );
}
