import React, { useMemo, useState, useEffect } from "react";
import { EditFormModalButton, NewFormModalButton } from "@shared/modal";
import { DeleteButton } from "@shared/form_fields";
import { TableEmpty, Column } from "@shared/tables/table";
import { AutoTable } from "@shared/tables/auto_table";
import { Card } from "@shared/card";
import { useQueryParams } from "@shared/filter_sort_paginate";
import { useAjax } from "@shared/ajax";
import { Search } from "@shared/search";
import { useRailsContext } from "@shared/utils";
import ManageCustomField from "./ManageCustomField";

function ManageFormModalButton({customField, setSelectedManageCustomField, setShowManageModal}) {
    return (
        <button className="rounded-md w-24 py-1 pl-2 pr-3 hover:bg-slate-50 font-medium text-slate-600 border-slate-300 border text-sm text-nowrap text-slate-600 border-slate-300 hover:bg-slate-50 inline-block text-sm"
            onClick={() => {
                setSelectedManageCustomField(customField)
                setShowManageModal(true)
            }}
        >
            <div className="flex justify-between items-center">
                <span className="mr-2">Manage</span>
                <img
                    height={16}
                    width={16}
                    src={require("../../../assets/images/settings-icon.svg")}
                />
            </div>
        </button>
    )
}

export default function CustomFieldIndex({ data }) {
    
    const current_user = useRailsContext().current_user

    const [params, setParams] = useQueryParams({
        sort_by: { default: "name" },
        sort_order: { default: "asc" },
        page: { type: "number", default: 1 },
        per_page: { type: "number", default: 50 },
        filters: { type: "object", default: [] },
        search: { type: "string", default: "" }
    });

    const {
        sort_by,
        sort_order,
        page,
        per_page,
        filters,
        search
    } = params

    const ajax = useAjax(data);
    const [customFields, setCustomFields] = useState(ajax.data)

    const [showManageModal, setShowManageModal] = useState(false)
    const [selectedManageCustomField, setSelectedManageCustomField] = useState(null)

    const filteredCustomFields = useMemo(() => {
        const customFieldTableItems = customFields?.items?.map(cField => {
            return {
                ...cField,
                type: cField.type.replace("Custom", "").replace("Field", "")
            }
        })
        if (!search) return customFieldTableItems
        return customFieldTableItems.filter((cField) => cField.name.toLowerCase().includes(search.toLowerCase()))
    }, [search, customFields])

    const ajaxRefreshForListChanges = () => {
        ajax.refresh()
    }

    useEffect(() => {
        setCustomFields(ajax.data)
    }, [ajax.data])

    if (!customFields) {
        return <div>Loading...</div>
    }

    return (
        <>
            <div className="flex justify-between items-center">
                <h1 className="font-semibold text-2xl text-slate-700">
                    Custom Fields
                </h1>
            </div>
            <div className="flex flex-col gap-6 py-4">
                <Card>
                    <div id="organizations" className="flex justify-between p-6">
                        <div className="flex grow">
                            <Search value={search} items={customFields.items.map((cField) => cField.name)} onChange={(search) => setParams({search})} className="z-10 w-4/5 md:w-3/5" />
                        </div>
                        <div className="px-3">
                            <NewFormModalButton label="New Custom Field" ajax={true} onSubmit={ajax.refresh} closeOnSubmit={true}
                            props={{customFields}} />
                        </div>
                    </div>
                    <AutoTable data={filteredCustomFields} loading={ajax.loading}
                        sortBy={sort_by} sortOrder={sort_order}
                        onSortChanged={(sortBy, sortOrder) => { setParams({ sort_by: sortBy, sort_order: sortOrder }) }}
                        currentPage={page} pageSize={per_page}
                        onPageChanged={page => setParams({ page })}
                    >
                        <TableEmpty>
                            <p className="p-6 text-gray-900"><i>No custom fields found.</i></p>
                        </TableEmpty>
                        <Column title="Name" field="name"/>
                        <Column title="Type" field="type"/>
                        <Column title="Label" field="label"/>
                        <Column title="Field Type" field="field_type"/>
                        <Column sortable={false} style={{width: "1%"}}>
                            {customField => {
                                customField = {
                                    ...customField,
                                    type: `Custom${customField.type}Field`
                                }
                                return (
                                    <div className="flex gap-3 items-center justify-end">
                                        {customField.field_type === 'list' &&
                                            <ManageFormModalButton 
                                                customField={customField} 
                                                setSelectedManageCustomField={setSelectedManageCustomField} 
                                                setShowManageModal={setShowManageModal}
                                            />
                                        }
                                        <EditFormModalButton instance={customField} className="w-24" ajax={true} onSubmit={ajax.refresh} closeOnSubmit={true}/>
                                        {current_user.master_admin &&
                                            <DeleteButton instance={customField} className="w-24" ajax={true} onDelete={ajax.refresh} modelLabel={"Custom Field"}/>
                                        }
                                    </div>
                                )
                            }
                            }
                        </Column>
                    </AutoTable>
                </Card>
            </div>
            <ManageCustomField customField={selectedManageCustomField} setCustomField={setSelectedManageCustomField} show={showManageModal} setShow={setShowManageModal} ajaxRefreshForListChanges={ajaxRefreshForListChanges}/>
        </>
    )
}
