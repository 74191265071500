import React from "react";
import { Card } from "@shared/card";
import { Tabs, Tab } from "@shared/tabs";
import { IndexLink } from "@shared/html_tags";
import { useRailsContext } from "@shared/utils";
import { useAjax } from "@shared/ajax";
import { MicrophoneIcon } from "@heroicons/react/20/solid";
import { AdjustmentsHorizontalIcon } from "@heroicons/react/24/outline";
import PromptsTab from "./prompts-tab";
import CustomFieldsTab from "./custom-fields-tab";

export default function EntityShow({ entity: data, custom_fields }) {
  const { entity_name } = useRailsContext();
  const ajax = useAjax(data);
  const entity = ajax.data;

  return (
    <>
      {/* <Flash /> */}

      <div className="flex justify-between items-center">
        <h1 className="font-semibold text-2xl text-gray-700">{entity.name}</h1>
      </div>

      <IndexLink to="entities" className="block mt-1 mb-4 font-normal text-sm">
        &larr; Back to {entity_name.plural}
      </IndexLink>

      <Card className="mt-3 py-1 px-0">
        <Tabs className="">
          <Tab
            title={
              <div className="inline-flex items-center">
                <MicrophoneIcon className="h-5 w-5 inline-block mr-2 stroke-2" />
                Prompts
              </div>
            }
          >
            <PromptsTab ajax={ajax} localeCodes={data.locale_codes} />
          </Tab>

          <Tab
            title={
              <div className="inline-flex items-center">
                <AdjustmentsHorizontalIcon className="h-5 w-5 inline-block mr-2 stroke-2" />
                Custom Fields
              </div>
            }
          >
            <CustomFieldsTab
              entity={entity}
              custom_fields={custom_fields}
              ajax={ajax}
            />
          </Tab>
        </Tabs>
      </Card>
    </>
  );
}
