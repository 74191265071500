import React, { useMemo, useState } from "react";
import { useObjectState } from "@shared/hooks";
import { Search } from "@shared/search";
import { EditFormModalButton, NewFormModalButton } from "@shared/modal";
import { organizationUrlFor } from "@shared/utils";
import { DeleteButton } from "@shared/form_fields";
import { AutoTable } from "@shared/tables/auto_table";
import { TableEmpty, Column } from "@shared/tables/table";
import ToggleWithConfirmation from "@shared/toggle-with-confirmation";

const FieldsTab = ({ ajax }) => {
  const [params, setParams] = useObjectState({
    sortBy: "name",
    sortOrder: "asc",
    page: 1,
    perPage: 50,
    search: "",
  });
  const { sortBy, sortOrder, page, perPage, search } = params;
  const { data: lookup_table } = ajax;
  const { lookup_table_fields } = lookup_table;

  const filteredLookupTableFields = useMemo(() => {
    if (!search) return lookup_table_fields;
    return lookup_table_fields.filter((lookup_tables_field) =>
      lookup_tables_field.name.toLowerCase().includes(search.toLowerCase())
    );
  }, [ajax.data, search]);

  return (
    <>
      <div id="lookup_table_fields" className="flex justify-between px-8 py-6">
        <div className="flex grow">
          <Search
            value={search}
            items={lookup_table_fields?.map(
              (lookup_table_field) => lookup_table_field.name
            )}
            onChange={(search) => setParams({ search })}
            className="z-10 w-4/5 md:w-3/5"
          />
        </div>

        <div>
          <NewFormModalButton
            className="pl-3"
            label="New Lookup Table Field"
            ajax={true}
            onSubmit={ajax.refresh}
            closeOnSubmit={true}
            model="lookup_table_field"
            template="lookup_table_fields/form"
            formAction={organizationUrlFor("lookup_table_fields")}
            props={{ lookup_table_id: lookup_table.id }}
          />
        </div>
      </div>

      <AutoTable
        data={filteredLookupTableFields}
        loading={ajax.loading}
        sortBy={sortBy}
        sortOrder={sortOrder}
        onSortChanged={(sortBy, sortOrder) => {
          setParams({ sortBy, sortOrder });
        }}
        currentPage={page}
        pageSize={perPage}
        onPageChanged={(page) => setParams({ page })}
      >
        <TableEmpty>
          <p className="p-6 text-gray-900">
            <i>No lookup table fields found.</i>
          </p>
        </TableEmpty>

        <Column title="Name" field="name" />
        <Column title="Label" field="label" />
        <Column title="Field Type" field="field_type" />
        <Column title="Show in Table" field="show_in_table">
          {(lookup_table_field) => {
            if (!lookup_table_field.is_lookup_key) {
              return (
                <ToggleWithConfirmation
                  instance={lookup_table_field}
                  fieldName="show_in_table"
                  fieldLabel="Show in Table"
                  checked={lookup_table_field.show_in_table}
                  onConfirm={() => {
                    ajax
                      .update(lookup_table_field, {
                        show_in_table: !lookup_table_field.show_in_table,
                      })
                      .then(() => {
                        ajax.refresh();
                      });
                  }}
                  getConfirmationMessage={(checked) => (
                    <>
                      Are you sure you want to{" "}
                      <strong>{checked ? "hide" : "show"}</strong> "
                      {lookup_table_field.name}" in table ?
                    </>
                  )}
                  getButtonLabel={(checked) =>
                    checked ? "Hide in Table" : "Show in Table"
                  }
                />
              );
            }
          }}
        </Column>

        <Column sortable={false} style={{ width: "1%" }}>
          {(lookup_table_field) => {
            if (!lookup_table_field.is_lookup_key) {
              return (
                <div className="flex gap-3 items-center">
                  {/* You'll want to check for actual permissions for this */}
                  <EditFormModalButton
                    instance={lookup_table_field}
                    className="w-24"
                    ajax={true}
                    onSubmit={ajax.refresh}
                    closeOnSubmit={true}
                    props={{}}
                  />
                  <DeleteButton
                    label={"Delete"}
                    instance={lookup_table_field}
                    className="w-24"
                    ajax={true}
                    onDelete={ajax.refresh}
                  />
                </div>
              );
            }
          }}
        </Column>
      </AutoTable>
    </>
  );
};

export default FieldsTab;
