import React from "react";
import { FormField, SelectField, ToggleField } from "@shared/form_fields";

export default function EntityForm({time_zones, groups, schedules, holiday_lists, custom_fields}) {
  return (
    <>
      <FormField field="name" />
      <FormField field="code" />
      <SelectField field="group_id" options={groups} />
      <SelectField field="schedule_id" options={schedules} />
      <SelectField field="holiday_list_id" options={holiday_lists} />
      {custom_fields?.map(field => 
        <span key={field.name}>
          {field.field_type == "toggle" && <ToggleField field={`custom_fields.${field.name}`} />}
          {field.field_type == "number" && <FormField type="number" field={`custom_fields.${field.name}`} />}
          {field.field_type == "text" && <FormField type="text" field={`custom_fields.${field.name}`} />}
          {field.field_type == "list" && <SelectField field={`custom_fields.${field.name}`} options={field.list.sort((a,b) => a.localeCompare(b))} label={`Custom Fields: ${field.name}`}></SelectField>}
        </span>
      )}
      <SelectField field="time_zone" label="Time Zone" options={time_zones} defaultLabel="- Default -" />
    </>
  )
}
