import React from "react";

import { Card } from "@shared/card";
import { Tabs, Tab } from "@shared/tabs";
import { IndexLink } from "@shared/html_tags";
import { useAjax } from "@shared/ajax";
import { TableCellsIcon, ViewColumnsIcon } from "@heroicons/react/20/solid";
import FieldsTab from "./fields-tab";
import EntriesTab from "./entries-tab";

export default function LookupTableShow({ lookup_table: data }) {
  const ajax = useAjax(data);
  const lookup_table = ajax.data;

  return (
    <>
      {/* <Flash /> */}

      <div className="flex justify-between items-center">
        <h1 className="font-semibold text-2xl text-slate-700">
          <span className="text-gray-500">Lookup Table:</span>{" "}
          {lookup_table.name}
        </h1>
      </div>

      <IndexLink
        to="lookup_tables"
        className="block mt-1 mb-4 font-normal text-sm"
      >
        &larr; Back to Lookup Tables
      </IndexLink>

      <Card className="mt-3 py-1 px-0">
        <Tabs className="">
          <Tab
            title={
              <div className="inline-flex items-center">
                <TableCellsIcon className="h-5 w-5 inline-block mr-2 stroke-2" />
                Lookup Table Fields
              </div>
            }
          >
            <FieldsTab ajax={ajax} />
          </Tab>

          <Tab
            title={
              <div className="inline-flex items-center">
                <ViewColumnsIcon className="h-5 w-5 inline-block mr-2 stroke-2" />
                Lookup Table Entries
              </div>
            }
          >
            <EntriesTab ajax={ajax} />
          </Tab>
        </Tabs>
      </Card>
    </>
  );
}
