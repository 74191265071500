import React, { useMemo, useState } from "react";
import { useObjectState } from "@shared/hooks";
import { Search } from "@shared/search";
import { EditFormModalButton, NewFormModalButton } from "@shared/modal";
import { labelize, organizationUrlFor } from "@shared/utils";
import { DeleteButton } from "@shared/form_fields";
import { AutoTable } from "@shared/tables/auto_table";
import { TableEmpty, Column } from "@shared/tables/table";
import ToggleWithConfirmation from "@shared/toggle-with-confirmation";

const EntriesTab = ({ ajax }) => {
  const [params, setParams] = useObjectState({
    sortBy: "lookup_key",
    sortOrder: "asc",
    page: 1,
    perPage: 50,
    search: "",
  });
  const { sortBy, sortOrder, page, perPage, search } = params;
  const { data: lookup_table } = ajax;
  const { lookup_table_entries, lookup_table_fields } = lookup_table;

  const filteredLookupTableEntries = useMemo(() => {
    if (!search) return lookup_table_entries;
    return lookup_table_entries.filter((lookup_table_entry) =>
      lookup_table_entry.lookup_key.toLowerCase().includes(search.toLowerCase())
    );
  }, [ajax.data, search]);

  return (
    <>
      <div id="lookup_table_entries" className="flex justify-between px-8 py-6">
        <div className="flex grow">
          <Search
            value={search}
            items={lookup_table_entries?.map(
              (lookup_table_entry) => lookup_table_entry.lookup_key
            )}
            onChange={(search) => setParams({ search })}
            className="z-10 w-4/5 md:w-3/5"
          />
        </div>

        <div>
          <NewFormModalButton
            className="pl-3"
            label="New Lookup Table Entry"
            ajax={true}
            onSubmit={ajax.refresh}
            closeOnSubmit={true}
            model="lookup_table_entry"
            template="lookup_table_entries/form"
            returnBoolean={true}
            formAction={organizationUrlFor("lookup_table_entries")}
            props={{
              lookup_table_id: lookup_table.id,
              fields: lookup_table_fields,
              lookup_table_lookup_field: lookup_table.lookup_key_field,
            }}
          />
        </div>
      </div>

      <AutoTable
        data={filteredLookupTableEntries}
        loading={ajax.loading}
        sortBy={sortBy}
        sortOrder={sortOrder}
        onSortChanged={(sortBy, sortOrder) => {
          setParams({ sortBy, sortOrder });
        }}
        currentPage={page}
        pageSize={perPage}
        onPageChanged={(page) => setParams({ page })}
      >
        <TableEmpty>
          <p className="p-6 text-gray-900">
            <i>No lookup table entries found.</i>
          </p>
        </TableEmpty>

        {lookup_table_fields
          .filter((field) => field.show_in_table)
          .sort(function (x, y) {
            return x.name == lookup_table.lookup_key_field
              ? -1
              : y.name == lookup_table.lookup_key_field
                ? 1
                : 0;
          })
          .map((field, i) => {
            if (field.field_type !== "Boolean") {
              return (
                <Column
                  key={field.name + "-" + i}
                  title={`${field.label} ${lookup_table.lookup_key_field === field.name ? "(lookup key)" : ""}`}
                  field={`fields.${field.name}`}
                  blankValue="-"
                >
                  {(lookup_table_entry) => {
                    if (lookup_table_entry.fields) {
                      return <>{lookup_table_entry.fields[field.name]}</>;
                    }
                  }}
                </Column>
              );
            } else {
              return (
                <Column
                  key={field.name + "-" + i}
                  title={`${field.label} ${lookup_table.lookup_key_field === field.name ? "(lookup key)" : ""}`}
                  field={`fields.${field.name}`}
                  blankValue="-"
                >
                  {(lookup_table_entry) => (
                    <ToggleWithConfirmation
                      instance={lookup_table_entry}
                      fieldName={field.label}
                      fieldLabel={field.label}
                      checked={lookup_table_entry.fields[field.name] === true}
                      onConfirm={() => {
                        const currentFields = lookup_table_entry?.fields;
                        currentFields[field.name] =
                          `${lookup_table_entry.fields[field.name] !== true}`;
                        ajax
                          .update(lookup_table_entry, {
                            fields: currentFields,
                          })
                          .then(() => {
                            ajax.refresh();
                          });
                      }}
                      getConfirmationMessage={() => (
                        <>
                          Are you sure you want to <strong>change</strong> "
                          {field.label}" in table?
                        </>
                      )}
                      getButtonLabel={() => "Change"}
                    />
                  )}
                </Column>
              );
            }
          })}

        <Column sortable={false} style={{ width: "1%" }}>
          {(lookup_table_entry) => (
            <div className="flex gap-3 items-center">
              {/* You'll want to check for actual permissions for this */}
              <EditFormModalButton
                instance={lookup_table_entry}
                className="w-24"
                ajax={true}
                onSubmit={ajax.refresh}
                closeOnSubmit={true}
                returnBoolean={true}
                props={{
                  lookup_table_id: lookup_table.id,
                  fields: lookup_table_fields,
                  lookup_table: lookup_table,
                }}
              />
              <DeleteButton
                label={"Delete"}
                instance={lookup_table_entry}
                className="w-24"
                ajax={true}
                instanceName={lookup_table_entry?.lookup_key}
                onDelete={ajax.refresh}
              />
            </div>
          )}
        </Column>
      </AutoTable>
    </>
  );
};

export default EntriesTab;
