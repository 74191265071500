import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  FormField,
  HiddenField,
  SelectField,
} from "@shared/form_fields";
import { Field, Label, Radio, RadioGroup, Textarea } from "@headlessui/react";

export default function PromptForm({
  entity_id,
  localeCodes,
  prompt,
  isUpdate,
}) {
  const [promptType, setPromptType] = useState("");
  const [promptValue, setPromptValue] = useState("");
  const [defaultLabelValue, setDefaultLabelValue] = useState("");
  const [oldAttachment, setOldAttachment] = useState();

  const pathName = window.location.pathname;
  let localCodesList = localeCodes ?? prompt?.locale_codes;
  const existingLocales = prompt?.attachmentlocales.map((a) => a.locale);

  localCodesList = localCodesList?.filter(
    (lc) =>
      !existingLocales?.includes(lc.locale) &&
      lc.locale_type === promptType.toLocaleLowerCase()
  );

  useEffect(() => {
    if (isUpdate) {
      const attachment = prompt.attachmentlocales.find(
        (l) => l.locale === isUpdate.rowId
      );

      setOldAttachment(attachment.id);

      const attachmentType = attachment?.attachment_text ? "Text" : "Audio";

      setPromptType(attachmentType);
      setDefaultLabelValue(isUpdate.rowId);

      if (attachmentType === "Text") {
        setPromptValue(attachment.attachment_text);
      }
    }
  }, [isUpdate]);

  const handlePromptTypeChange = (type) => {
    setPromptType(type);
  };

  const renderPromptType = () => {
    if (promptType === "Audio") {
      return (
        <UploadFileField
          name="prompt[attachments][]"
          label="Upload"
          buttonLabel={"Select File"}
          className="bg-white my-2 w-[224px] hover:bg-primary-50 border border-dashed border-primary-600 text-primary-600"
        />
      );
    }

    if (promptType === "Text") {
      return (
        <>
          <label className="block mm-2">Text Prompt</label>
          <Textarea
            name="prompt[attachment_text][]"
            placeholder="Write the message here"
            className={"w-[14rem] my-2"}
            value={promptValue}
            onChange={(e) => {
              setPromptValue(e.target.value);
            }}
          ></Textarea>
        </>
      );
    }
    return null;
  };

  const renderPromptValueField = () => {
    return (
      <div className="bg-primary-50 mt-3 mb-2 pt-2 px-3 pb-1 rounded-md min-w-[250px]">
        <label className="block mb-2">Prompts</label>
        <RadioGroup
          aria-label="prompt"
          value={promptType}
          className="mb-4"
          onChange={(e) => {
            handlePromptTypeChange(e);
          }}
        >
          <Field key={"audio"} className="flex items-center gap-2 mb-2">
            <Radio
              value="Audio"
              className="group flex size-5 items-center justify-center rounded-full border bg-white data-[checked]:bg-primary"
            >
              <span className="invisible size-2 rounded-full bg-white group-data-[checked]:visible" />
            </Radio>
            <Label>Audio</Label>
          </Field>
          <Field key={"text"} className="flex items-center gap-2">
            <Radio
              value="Text"
              className="group flex size-5 items-center justify-center rounded-full border bg-white data-[checked]:bg-primary"
            >
              <span className="invisible size-2 rounded-full bg-white group-data-[checked]:visible" />
            </Radio>
            <Label>Text</Label>
          </Field>
        </RadioGroup>

        <SelectField
          field={"locale_code"}
          options={localCodesList?.map((x) => ({
            label: x.locale,
            value: x.locale,
          }))}
          defaultLabel={defaultLabelValue}
        />
        {renderPromptType()}
      </div>
    );
  };

  return (
    <>
      {entity_id && <HiddenField field="entity_id" value={entity_id} />}
      {prompt && <HiddenField field="name" value={prompt.name} />}
      {prompt && <HiddenField field="description" value={prompt.description} />}
      {prompt && <HiddenField field="id" value={prompt.id} />}
      {prompt && (
        <HiddenField field="isUpdate" value={isUpdate ? true : false} />
      )}
      {isUpdate && (
        <HiddenField
          field="old_attachment_value"
          value={JSON.stringify(oldAttachment)}
        />
      )}

      <div>
        {pathName.includes("entities") ? (
          <>
            <FormField field="name" />
            <FormField field="description" />
          </>
        ) : (
          <>{renderPromptValueField()}</>
        )}
      </div>
    </>
  );
}

function UploadFileField({
  name,
  label,
  buttonLabel = "Select File",
  className,
  onChange = () => {},
}) {
  const fileInputRef = useRef();
  const [fileSelected, setFileSelected] = useState(false);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const files = event.target.files;
    onChange(files);
    setFileSelected(true);
  };

  return (
    <div>
      <label htmlFor={name} className="block">
        {label}
      </label>
      <Button type="button" className={className} onClick={handleButtonClick}>
        {fileSelected ? "Select new file" : buttonLabel}
      </Button>
      <input
        name={name} // Set the name attribute to an array
        type="file"
        ref={fileInputRef}
        accept="audio/*"
        onChange={handleFileChange}
        style={{ display: "none" }}
      />
    </div>
  );
}
