import React, { Fragment, useState } from 'react'
import { Combobox, Transition } from '@headlessui/react'
import { useEffectAfterMount } from "@shared/utils";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

interface SearchProps {
  value?: string
  items: string[]
  className?: string
  onChange?: (value: string) => void
}

export const Search = ({ value = "", items, className, onChange } : SearchProps) => {
  const [query, setQuery] = useState(value);

  const fiteredItems =
    (query === "" || query === null
      ? items
      : items.filter((item) =>
          item.toLowerCase()
            .replace(/\s+/g, '')
            .includes(query.toLowerCase().replace(/\s+/g, ''))
        )
    )?.sort((a, b) => a?.localeCompare(b));

  useEffectAfterMount(() => {
    onChange && onChange(query);
  }, [query]);

  const buttonRef = React.useRef(null);

  return (
    <div className={className}>
      <Combobox value={query} onChange={setQuery} nullable={true}>  {/* immediate={true}> this will be supported in headlessui v2, open menu on focus */}
        <div className="relative">
          <div className="relative w-full cursor-default overflow-hidden bg-white text-left border-b border-gray-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
            <Combobox.Button ref={buttonRef} className="absolute inset-y-0 left-0 flex items-center pl-2">
              <MagnifyingGlassIcon
                className="h-5 w-5 text-gray-600"
                aria-hidden="true"
              />
            </Combobox.Button>
            <Combobox.Input
              className="w-full border-none py-2 pl-10 pr-3 text-sm leading-5 text-gray-900 focus:ring-0"
              onChange={event => setQuery(event.target.value)}
              // displayValue={() => query}
              value={query || "" }
              placeholder="Search..."

              // Open the menu on focus
              // We won't need this in headlessui v2, can just use the immediate prop
              onClick={() => {
                if (!open) {
                  buttonRef.current?.click()
                }
              }}
              onFocus={() => {
                buttonRef.current?.click()
              }}
            />
          </div>
          {items.length > 0 &&
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                {/* This bit helps make sure that the focused option doesn't auto-replace the input value, which seems to happen by default with this component. */}
                {/* Basically this creates a hidden option in the menu that matches whatever is currently in the input. */}
                {query?.length > 0 && (
                  <Combobox.Option value={query} className="hidden">
                    {query}
                  </Combobox.Option>
                )}

                {(
                  fiteredItems.map((item) => (
                    <Combobox.Option
                      key={item}
                      className={({ active }) =>
                        `relative cursor-default select-none py-2 pl-10 pr-4 ${
                          active ? 'bg-primary-50' : 'text-gray-900'
                        }`
                      }
                      value={item}
                    >
                      <span className="block truncate">
                        {item}
                      </span>
                    </Combobox.Option>
                  ))
                )}

                {fiteredItems.length === 0 && (
                  <div className="cursor-default select-none py-2 pl-10 pr-4 text-gray-900">
                    <span className="block truncate">
                      No results found
                    </span>
                  </div>
                )}
              </Combobox.Options>
            </Transition>
          }
        </div>
      </Combobox>
    </div>
  )
}
