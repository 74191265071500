import React, { ReactElement, useEffect, useRef } from "react";
import { RailsContext, RailsContextParams } from "@shared/rails_context";

export const useRailsContext : () => RailsContextParams = () => {
  const context = React.useContext(RailsContext);
  if (!context) {
    throw new Error("useRailsContext must be used within a RailsContextProvider");
  }
  return context;
}

export const find = (array, id) => {
  return array.find((item) => item.id === id);
}

/**
 *  Like useEffect, but doesn't run on the first render
 */
export const useEffectAfterMount = (fn, dependencies) => {
  const didMount = useRef(false);
  useEffect(() => {
      if (didMount.current) {
        fn();
      } else {
        didMount.current = true;
      }
  }, dependencies);
}

export const filterChildrenByType = (children, type) => {
  return React.Children.toArray(children).filter(child => (child as ReactElement).type === type);
}

export const findChildByType = (children, type) => {
  return React.Children.toArray(children).find(child => (child as ReactElement).type === type);
}

export function deepEqual(object1, object2) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      (areObjects && !deepEqual(val1, val2)) ||
      (!areObjects && val1 !== val2)
    ) {
      return false;
    }
  }

  return true;
}

export function isObject(object) {
  return object != null && typeof object === 'object';
}

/**
 * Returns a nested value from an object using a string key using dot notation (e.g. "user.name")
 *
 * @param obj
 * @param key
 * @returns the value of the nested key or undefined if it doesn't exist
 */
export function getNestedValueFromString(obj, key) {
  const keys = key.split(".") || [""]
  return getNestedValue(obj, keys);
}

export function getNestedValue(obj, keys) {
  return keys.reduce((o, key) => (o && key in o) ? o[key] : undefined, obj);
}

export function setNestedValue(obj, keys, value) {
  keys.reduce((o, key, i) => {
    if (i === keys.length - 1) {
      o[key] = value;
    } else if (!o[key]) {
      o[key] = {};
    }
    return o[key];
  }, obj);
}

export const fileToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = error => reject(error);
      reader.readAsDataURL(file);
  });
};

export const organizationUrlFor = (url: string): string => {
  const { current_organization } = useRailsContext();
  return `/${current_organization?.id}/${url}`;
}

export function labelize(str) {
  return <span className="capitalize">{str.replace(/_/g, " ").replace(/\./g, ": ")}</span>;
}

// Convert days object to a human-readable comma-separated string of selected days
export function daysToString(days) {
  return Object.keys(days).filter(day => days[day]).join(", ");
}

// Convert days object to a bitmask string of 1s and 0s
// e.g. {mon: true, tue: false, wed: true, thu: false, fri: true, sat: false, sun: false} => "1010100"
// We use this for clever sorting of days (earlier days first)
export function daysToBitmask(days) {
  return Object.keys(days).map(day => days[day] ? "1" : "0").join("");
}
