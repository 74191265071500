import React, { useMemo } from "react";
import { Popover, Transition } from "@headlessui/react";
import {
  ChevronDownIcon,
  XMarkIcon,
  PlusIcon,
} from "@heroicons/react/20/solid";
import {
  FormModalLink,
  ShowFormModalButton,
  EditFormModalButton,
  NewFormModalButton,
} from "@shared/modal";
import { DeleteButton } from "@shared/form_fields";
import { Table, TableEmpty, Column } from "@shared/tables/table";
import { AutoTable } from "@shared/tables/auto_table";
import Flash from "@shared/flash";
import { Card } from "@shared/card";
import { ShowLink } from "@shared/html_tags";
import { useQueryParams } from "@shared/filter_sort_paginate";
import { useAjax } from "@shared/ajax";
import { Search } from "@shared/search";

export default function LookupTableIndex({ data }) {
  const [params, setParams] = useQueryParams({
    sort_by: { default: "id" },
    sort_order: { default: "asc" },
    page: { type: "number", default: 1 },
    per_page: { type: "number", default: 50 },
    filters: { type: "object", default: [] },
    search: { type: "string", default: "" },
  });

  const { sort_by, sort_order, page, per_page, filters, search } = params;

  const ajax = useAjax(data);
  const lookup_tables = ajax.data.items;

  const filteredLookup_tables = useMemo(() => {
    if (!search) return lookup_tables;
    return lookup_tables.filter((lookup_table) =>
      lookup_table.name.toLowerCase().includes(search.toLowerCase())
    );
  }, [ajax.data, search]);

  return (
    <>
      <Flash />

      <div className="flex justify-between items-center">
        <h1 className="font-semibold text-2xl text-slate-700">Lookup tables</h1>
      </div>

      <Card className="mt-3 pb-6">
        <div id="lookup_tables" className="flex justify-between p-8">
          <div className="flex grow">
            <Search
              value={search}
              items={lookup_tables.map((lookup_table) => lookup_table.name)}
              onChange={(search) => setParams({ search })}
              className="z-10 w-4/5 md:w-3/5"
            />
          </div>

          <div>
            <NewFormModalButton
              className="pl-3"
              label="New Lookup table"
              ajax={true}
              onSubmit={ajax.refresh}
              closeOnSubmit={true}
              props={{}}
            />
          </div>
        </div>

        {/* <Table */}
        <AutoTable
          data={filteredLookup_tables}
          loading={ajax.loading}
          sortBy={sort_by}
          sortOrder={sort_order}
          onSortChanged={(sortBy, sortOrder) => {
            setParams({ sort_by: sortBy, sort_order: sortOrder });
          }}
          currentPage={page}
          pageSize={per_page}
          onPageChanged={(page) => setParams({ page })}
        >
          <TableEmpty>
            <p className="p-6 text-gray-900">
              <i>No lookup tables created yet</i>
            </p>
          </TableEmpty>

          <Column title="Name" field="name" blankValue="-">
            {(lookup_table) => (
              <ShowLink instance={lookup_table}>{lookup_table.name}</ShowLink>
            )}
          </Column>
          <Column
            title="Lookup key field"
            field="lookup_key_field"
            blankValue="-"
          />
          <Column sortable={false} style={{ width: "1%" }}>
            {(lookup_table) => (
              <div className="flex gap-3 items-center">
                {/* You'll want to check for actual permissions for this */}
                <EditFormModalButton
                  instance={lookup_table}
                  className="w-24"
                  ajax={true}
                  onSubmit={ajax.refresh}
                  closeOnSubmit={true}
                  props={{isEdit :true}}
                />
                <DeleteButton
                  instance={lookup_table}
                  className="w-24"
                  ajax={true}
                  onDelete={ajax.refresh}
                />
              </div>
            )}
          </Column>
        </AutoTable>
      </Card>
    </>
  );
}
