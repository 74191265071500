import React from "react";
import { FormField, HiddenField, SelectField, ToggleField } from "@shared/form_fields";

export default function LookupTableFieldForm({lookup_table_id}) {
  return (
    <>
      <HiddenField field="lookup_table_id" value={lookup_table_id} />

      <FormField field="name" />
      <FormField field="label" />
      <SelectField
        field="field_type"
        defaultSelected={"Text"}
        defaultLabel={""}
        showDefaultValue={false}
        options={[
          { id: "Text", name: "Text" },
          { id: "Number", name: "Number" },
          { id: "Boolean", name: "Boolean" },
        ]}
      />
      <ToggleField defaultChecked={true} label={"Show in table"} field="show_in_table" />
    </>
  )
}
