import { IconButton } from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import React from "react";
import { styled } from "@mui/material/styles";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import MenuItemList from "./MenuItemList";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }): any => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const MenuDrawer = ({ open, handleDrawerClose, props }) => {
  return (
    <>
      <Drawer
        className={`mobile-drawer ${open ? "opened" : "closed"}`}
        variant="permanent"
        open={open}
      >
        <DrawerHeader className="!p-0">
          <img
            className="max-w-[50px] m-auto"
            src={require("../../../assets/images/cloverhound-logo.png")}
          />
          {open && (
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          )}
        </DrawerHeader>
        <MenuItemList
          open={open}
          props={props}
          items={[
            "groups",
            "schedules",
            "holiday lists",
            "lookup tables",
            "users",
            "settings",
            "documentation"
          ]}
        />
      </Drawer>
    </>
  );
};

export default MenuDrawer;
