import React, { useEffect } from 'react'
import { Modal } from '@shared/modal'
import CustomFieldsListForm from './CustomFieldsListForm'

const ManageCustomField = ({customField, setCustomField, show, setShow, ajaxRefreshForListChanges}) => {
    const handleCancel = () => {
        setShow(false)
    }

    return (
        <Modal show={show} onClose={handleCancel} size='full' ref={null}>
            <form>
                <CustomFieldsListForm customField={customField} setCustomField={setCustomField} setShow={setShow} ajaxRefreshForListChanges={ajaxRefreshForListChanges}/>
            </form>
        </Modal>
    )
}

export default ManageCustomField
