import React, { Fragment, useEffect } from "react";
import { twMerge } from 'tailwind-merge'
import { setNestedValue, useRailsContext, fileToBase64 } from "./utils";
import { FormContext, CsrfToken, OutlineButton } from "./form_fields";
import ReactRailsUJS from "react_ujs"
import { Dialog, Transition } from "@headlessui/react";
import { Spinner } from "./spinners";
import RailsErrors from "./rails-errors";

export const Modal =  React.forwardRef<any, any>(({ children, show, onClose = () => {}, className = "", size = "md", disableBackdropClose = false }, ref) => {
  const handleBackdropClick = () => {
    !disableBackdropClose && onClose();
  };

  const sizeClass = (size = "md") => {
    const sizeClasses = {
      "sm": "max-w-sm",
      "md": "max-w-md",
      "lg": "max-w-lg",
      "xl": "max-w-xl",
      "2xl": "max-w-2xl",
      "3xl": "max-w-3xl",
      "4xl": "max-w-4xl",
      "5xl": "max-w-5xl",
      "6xl": "max-w-6xl",
      "7xl": "max-w-7xl",
      "full": "max-w-full"
    };
    return sizeClasses[size] || sizeClasses["md"];
  }

  return (
    <Transition appear show={show} as={Fragment}
      enter="ease-out duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="ease-in duration-200"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <Dialog className="relative z-[1500]" onClose={handleBackdropClick}>
        <div className="fixed inset-0 bg-black/50"></div> {/* Backdrop */}
        <div className="fixed inset-0 flex overflow-y-auto">
        <div className="m-auto max-h-full overflow-container">
            <div className="flex flex-cel justify-center">
                <div ref={ref}
                     className={twMerge(`${sizeClass(size)} modal-main bg-white rounded-lg shadow-lg mx-auto my-4 transition-opacity duration-300`, className)}>
                    <Dialog.Panel>
                        {children}
                    </Dialog.Panel>
                </div>
            </div>
        </div>
        </div>
      </Dialog>
    </Transition>
  );
});

export const FormModal = ({
  children,
  show,
  size = "md",
  action = "show",
  formAction = null,
  method = null,
  model = null,
  controller = null,
  title = null,
  submitButtonLabel = null,
  instance = {} as any,
  props = {},
  handleClose,
  onSubmit = null,
  readOnly = null,
  ajax = false,
  closeOnSubmit = false,
  nestedInOrganization = true,
  template = null
}) => {

  const railsContext = useRailsContext();

  const [disabled, setDisabled] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [submitted, setSubmitted] = React.useState(false);
  const [result, setResult] = React.useState(null);

  // Used to prevent rendering the modal content until it's been shown at least once,
  // avoiding running every single form's initializers which may perform AJAX requests and such
  const [initialized, setInitialized] = React.useState(false);
  useEffect(() => {
    if (show && !initialized) {
      setInitialized(true);
    }
  }, [show]);

  const handleCurtainClick = (event) => {
    if (event.target === event.currentTarget) {
      handleClose();
    }
  };

  const handleCancel = (event = null) => {
    event?.preventDefault();
    handleClose();
    setTimeout(() => {
      reset();
    }, 500);
  }

  const reset = () => {
    setLoading(false);
    setError(null);
    setSubmitted(false);
    setResult(null);
    setDisabled(false);
  }
  const enable = () => setDisabled(false);
  const disable = () => setDisabled(true);

  const handleSubmit = async (event) => {
    setLoading(true);

    if (!ajax) {
      // If not using AJAX, let the form submit normally
      if (onSubmit) {
        onSubmit(event);
      }

      return;
    }

    event.preventDefault(); // Prevent default form submission only for AJAX

    const formData : any = new FormData(event.target);
    // const jsonBody = {};
    // // Formats the form data as JSON objects per Rails naming convention
    // // e.g., "user[name]" becomes `{ user: { name: "John" } }`
    // for (let [key, value] of formData.entries()) {
    //   // Split the key based on Rails naming convention (e.g., "user[name]")
    //   const regex = /^([^\[]+)|\[([^\]]+)\]/g;
    //   // const match = key.matchAll(/(\w+)(\[(\w*)\])+/g);
    //   let match = regex.exec(key);
    //   let parentKey = match[0];
    //   let childKeys = [];
    //   while ((match = regex.exec(key)) !== null) {
    //     childKeys.push(match[2]);
    //   }

    //   if (value instanceof File) {
    //     value = await fileToBase64(value);  // Converts file to Base64
    //   }

    //   if (childKeys.length > 0) {
    //     setNestedValue(jsonBody, childKeys, value);
    //   } else {
    //     // Handle keys that don't use the Rails naming convention (e.g., just "name")
    //     jsonBody[parentKey] = value;
    //   }
    // }

    const method = formData.get('_method') || 'post';
    try {
      const response = await fetch(event.target.action, {
        method: method,
        headers: {
          // "Content-Type": "application/json",
          "Accept": "application/json",
          "X-CSRF-Token": railsContext.csrf_token
        },
        body: formData //JSON.stringify(jsonBody)
      });
      const result = await response.json();

      if (!response.ok) {
        console.warn(`Error submitting via ajax: ${response.status} - ${response.statusText}`, result);
        setLoading(false);
        setError(result);
        setSubmitted(false);
        // Scroll to the top of the modal to show the error message
        modalRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });

        return;
      }

      setLoading(false);
      setSubmitted(true);
      setResult(result);
      if (onSubmit) {
        onSubmit(result);
      }
      if (closeOnSubmit) {
        handleCancel();
      }

    } catch (error) {
      console.error("Error submitting via ajax", error);
      setLoading(false);
      setError(error);
      setSubmitted(false);
      // Scroll to the top of the modal to show the error message
      modalRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  if (!model) {
    model = instance.__model_name || railsContext.model_name;
  }

  if (!title) {
    const titleizedModel = model.replace(/_/g, " ");
    if (action === "show") {
      title = titleizedModel;
    } else {
      title = `${action} ${titleizedModel}`;
    }
  }

  // We disable the form if it's loading or submitted to prevent double submissions
  // To re-enable the form after submit, you can call the `reset` function
  if (readOnly === null) {
    readOnly = (action === "show") || disabled || loading || submitted;
  }
  const buttonsDisabled = disabled || loading || (closeOnSubmit && submitted);

  if (!method) {
    method = "post";
    if (action === "edit" || action === "show") {
      method = "put";
    }
  }

  if (!controller) {
    controller = instance.__controller_name || railsContext.controller_name;
  }

  if (!formAction) {
    formAction = `/${controller}`
    if (action === "edit" || action === "show") {
      formAction += `/${instance.id}`
    }

    if (railsContext.current_organization?.id && nestedInOrganization) {
      formAction = `/${railsContext.current_organization.id}${formAction}`
    }
  }

  // If no children are passed in, we'll try to render a form component based on the controller
  // using the convention that the form component is located at `app/javascript/components/${controller}/form.jsx`
  if (React.Children.count(children) === 0) {
    let componentPath = template || `${controller}/form`;
    const FormComponent = ReactRailsUJS.getConstructor(componentPath);
    children = <FormComponent {...props} />;
  }

  const modalRef = React.useRef(null);

  return (
    // <div className={`modal ${show ? "opacity-100" : "opacity-0 pointer-events-none"} transition-opacity duration-100 fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 overflow-y-auto z-50`} onClick={handleCurtainClick}>

      <Modal show={show} onClose={handleCancel} size={size} ref={modalRef}>
        <form action={formAction} method="POST" onSubmit={handleSubmit} >

          {/* Modal header (form title) */}
          <section className="modal-header p-4 border-b border-gray-200">
            <span className="capitalize">{title}</span>
          </section>

          {/* Modal body (render the actual form passed in) */}
          <section className="modal-body pt-2 pb-6 px-8">
            {error && (
              <div className="p-4 bg-red-100 border border-red-300 rounded-md text-red-700">
                <div className="font-semibold">Errors:</div>
                <div>{error.message || <RailsErrors errors={error} />}</div>
              </div>
            )}

            <FormContext.Provider value={{ model, action, readOnly, instance, shown: show, loading, submitted, error, reset, enable, disable, result, close: handleCancel }}>
                <input name="_method" type="hidden" value={method} />
                <CsrfToken />

                {initialized && children}
            </FormContext.Provider>
          </section>

          {/* Modal footer (submit/cancel/close buttons) */}
          <section className="modal-footer p-4 flex justify-stretch gap-2">
            {action === "show" ? (
              <button disabled={buttonsDisabled} onClick={handleCancel} className="rounded-lg py-2 px-8 bg-primary hover:bg-primary-600 text-white block font-medium w-full">
                Close
              </button>
            ) : (
              ajax && submitted && !closeOnSubmit ? (
                <button disabled={buttonsDisabled} onClick={handleCancel} className="rounded-lg py-2 px-8 bg-primary hover:bg-primary-600 text-white block font-medium w-full">
                  Done
                </button>
              ) : (
                <>
                  <button disabled={buttonsDisabled} type="button" onClick={handleCancel} className="rounded-lg py-2 px-8 bg-gray-200 hover:bg-gray-300 text-black block font-medium w-full">
                    Cancel
                  </button>
                  <button disabled={buttonsDisabled} type="submit" className="rounded-lg py-2 px-8 bg-primary hover:bg-primary-600 text-white block font-medium w-full">
                    {loading || (closeOnSubmit && submitted) ?
                      <div className="flex justify-center">
                        <Spinner size="sm" ariaLabel="Loading..."/>
                      </div>
                      :
                      submitButtonLabel || (action === "new" ? "Create" : "Save")
                    }
                  </button>
                </>
              )
            )}
          </section>
        </form>
    </Modal>


    // </div>
  );
}

export const FormModalLink = ({ children, label, size, className = "", action, instance, props }) => {
  const [show, setShow] = React.useState(false);

  return (
    <>
      <a onClick={() => setShow(true)} className={twMerge("cursor-pointer text-primary-700 hover:text-primary-600 hover:underline font-semibold", className)}>
        {label}
      </a>

      <FormModal handleClose={() => setShow(false)} show={show} size={size} action={action} instance={instance} props={props}>
        {children}
      </FormModal>
    </>
  );
}

export const FormModalButton = ({ className, label, children, ...props }) => {
  const [show, setShow] = React.useState(false);
  return (
    <>
      <OutlineButton onClick={() => setShow(true)} className={className}>
        {label}
      </OutlineButton>

      <FormModal handleClose={() => setShow(false)} show={show} {...props} >
        {children}
      </FormModal>
    </>
  );
}

export const SecondaryFormModalButton = (props) => FormModalButton({ ...props, className: twMerge("text-slate-600 border-slate-300 hover:bg-slate-50 inline-block text-sm", props.className)});

export const ShowFormModalButton = (props) => {
  const label = props.label || "Show";

  return <SecondaryFormModalButton {...props} readOnly={true} label={
    <div className="flex justify-between items-center pl-2"> {/* flex to align icon and text */}
      <span>{label}</span>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 inline-block align-text-top opacity-75">
        <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
        <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
      </svg>

    </div>
  }/>
}

export const EditFormModalButton = (props) => {
  const label = props.buttonText || "Edit";

  return <SecondaryFormModalButton action="edit" {...props} className={twMerge("w-24", props.className)} label={
    <div className="flex justify-between items-center pl-2"> {/* flex to align icon and text */}
      {props.customBtn ? (
            props.customBtn
          ) : (
            <>
              <span>{label}</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-4 h-4 inline-block align-text-top opacity-75"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"
                />
              </svg>
            </>
          )}
    </div>
  }/>
}

export const NewFormModalButton = (props) => {
  return <FormModalButton {...props} action="new" label={
    <span>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5 mr-1 inline-block align-text-top">
        <path d="M10.75 4.75a.75.75 0 0 0-1.5 0v4.5h-4.5a.75.75 0 0 0 0 1.5h4.5v4.5a.75.75 0 0 0 1.5 0v-4.5h4.5a.75.75 0 0 0 0-1.5h-4.5v-4.5Z" />
      </svg>
      {props.label}
    </span>
  } />
}
