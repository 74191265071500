import React, { useMemo } from "react";
import { useObjectState } from "@shared/hooks";
import AudioManager from "@shared/audio-manager";
import { Search } from "@shared/search";
import { EditFormModalButton, NewFormModalButton } from "@shared/modal";
import { organizationUrlFor, useEffectAfterMount } from "@shared/utils";
import { AutoTable } from "@shared/tables/auto_table";
import { TableEmpty, Column } from "@shared/tables/table";
import { DeleteButton } from "@shared/form_fields";
import { ShowLink } from "@shared/html_tags";

const PromptsTab = ({ ajax, localeCodes }) => {
  const [params, setParams] = useObjectState({
    sortBy: "name",
    sortOrder: "asc",
    page: 1,
    perPage: 50,
    search: "",
  });
  const { sortBy, sortOrder, page, perPage, search } = params;
  const { data: entity } = ajax;
  const { prompts } = entity;

  const filteredPrompts = useMemo(() => {
    if (!search) return prompts;
    return prompts.filter((prompt) =>
      prompt.name.toLowerCase().includes(search.toLowerCase())
    );
  }, [ajax.data, search]);

  useEffectAfterMount(() => {
    AudioManager.reset();
  }, [ajax.data]);

  return (
    <>
      <div id="prompts" className="flex justify-between px-8 py-6">
        <div className="flex grow">
          <Search
            value={search}
            items={prompts.map((prompt) => prompt.name)}
            onChange={(search) => setParams({ search })}
            className="z-10 w-4/5 md:w-3/5"
          />
        </div>

        <div>
          <NewFormModalButton
            className="pl-3"
            label="New Prompt"
            ajax={true}
            onSubmit={ajax.refresh}
            closeOnSubmit={true}
            model="prompt"
            template="prompts/form"
            formAction={organizationUrlFor("prompts")}
            props={{ entity_id: entity.id, localeCodes: localeCodes }}
          />
        </div>
      </div>

      <AutoTable
        data={filteredPrompts}
        loading={ajax.loading}
        sortBy={sortBy}
        sortOrder={sortOrder}
        onSortChanged={(sortBy, sortOrder) => {
          setParams({ sortBy, sortOrder });
        }}
        currentPage={page}
        pageSize={perPage}
        onPageChanged={(page) => setParams({ page })}
      >
        <TableEmpty>
          <p className="p-6 text-gray-900">
            <i>No prompts found.</i>
          </p>
        </TableEmpty>

        <Column title="Name" field="name">
          {(entity) => (
            <ShowLink instance={entity} className={""}>
              {entity.name}
            </ShowLink>
          )}
        </Column>
        <Column title="Description" field="description" />

        <Column title="Languages" sortable={false}>
          {(prompt) => prompt.attachmentlocales?.length}
        </Column>

        <Column sortable={false} style={{ width: "1%" }}>
          {(prompt) => (
            <div className="flex gap-3 items-center">
              {/* You'll want to check for actual permissions for this */}
              <EditFormModalButton
                instance={prompt}
                ajax={true}
                onSubmit={ajax.refresh}
                closeOnSubmit={true}
                props={{}}
              />
              <DeleteButton
                instance={prompt}
                className="w-24"
                ajax={true}
                onDelete={ajax.refresh}
              />
            </div>
          )}
        </Column>
      </AutoTable>
    </>
  );
};

export default PromptsTab;
