import { useRailsContext } from '@shared/utils'
import React, { useEffect, useMemo, useState } from 'react'
import { updateCustomFieldListOptions } from 'components/services/customFieldsService'
import { Search } from '@shared/search'

const CustomFieldsListForm = ({customField, setCustomField, setShow, ajaxRefreshForListChanges}) => {
    const railsContext = useRailsContext()
    const {current_organization} = railsContext
    const [userInputListValue, setUserInputListValue] = useState('')
    const [userInputSearch, setUserInputSearch] = useState('')
    const [userInputError, setUserInputError] = useState(null)
    const [currentListOptions, setCurrentListOptions] = useState([...customField?.list])

    const saveChanges = (e) => {
        e.preventDefault()
        updateCustomFieldListOptions(customField, current_organization.id).then(() => ajaxRefreshForListChanges())
    }

    const addValueToList = (e) => {
        e.preventDefault()
        // Prevent duplicates values in the list
        if (!userInputListValue) {setUserInputError('Value cannot be blank'); return}
        if (currentListOptions.includes(userInputListValue)) {setUserInputError(`${userInputListValue} is already included in the list`);  return}
        setCurrentListOptions((prevList) => [...prevList, userInputListValue])
        const updatedCustomField = {
            ...customField,
            list: [...customField?.list, userInputListValue]
        }
        setCustomField(updatedCustomField)
        setUserInputListValue('')
    }

    const deleteValueFromList = (e, listValue) => {
        e.preventDefault()
        const filteredListOptions = currentListOptions.filter(option => option !== listValue)
        setCurrentListOptions(filteredListOptions)
        const updatedCustomField = {
            ...customField,
            list: filteredListOptions
        }
        setCustomField(updatedCustomField)
        setUserInputListValue('')
    }

    const filteredListSelectValues = useMemo(() => {
        if (!userInputSearch) return currentListOptions
        return currentListOptions.filter(option => option.toLowerCase().includes(userInputSearch.toLowerCase()))
    }, [customField, userInputSearch])

    useEffect(() => {
        if (userInputError) setUserInputError(null)
    }, [userInputListValue])

    return (
        <>
            <section className="modal-header p-4 border-b border-gray-200">
                <span className="capitalize">Manage</span>
            </section>
            <section className="modal-body pt-2 px-8 mt-4 mb-8">
                {userInputError && <p className='text-red-500'>{userInputError}</p>}
                <div className="flex flex-row justify-between items-end">
                    <div className='flex flex-col justify-between align-center'>
                        <label>Predefined Value</label>
                        <input className='border-2 border-black rounded-md p-1' style={{width: '300px'}} value={userInputListValue} onChange={(e) => setUserInputListValue(e.target.value)}/>
                    </div>
                    <button style={{borderColor: "#176E60", color: "#176E60"}} className="rounded-md py-1 px-3 ml-4 hover:bg-slate-50 font-medium border text-sm text-nowrap"
                        onClick={addValueToList}
                    >
                        <div className="flex justify-between items-center">
                            <img
                                height={24}
                                width={24}
                                src={require("../../../assets/images/add-icon.svg")}
                            />
                            <span className="text-lg">&nbsp;&nbsp;Add</span>
                        </div>
                    </button>
                </div>
            </section>
            <section className="modal-body pt-2 px-8 mt-4 mb-8">
                <div className='flex grow'>
                    <Search value={userInputSearch} items={[]} onChange={(search) => setUserInputSearch(search)} className="z-10 w-full" />
                </div>
            </section>
            <section className="modal-body p-4 m-4 flex flex-col justify-stretch gap-2 bg-slate-200 rounded-md">
                {filteredListSelectValues.sort((a,b) => a.localeCompare(b)).map((listValue) => 
                    <div key={listValue} className='flex flex-row justify-between bg-white p-2 m-1 rounded-md'>
                        <div>{listValue}</div>
                        <button className='rounded-md border-blue-500'
                            onClick={(e) => deleteValueFromList(e, listValue)}
                        >
                            <div className="flex justify-between items-center pl-2">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="#1e2787" className="w-5 h-5 inline-block align-text-top opacity-75">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                </svg>
                            </div>
                        </button>
                    </div>
                )}
            </section>
            <section className='modal-footer flex justify-center gap-2 mt-8 mb-4 mx-4'>
                <button type="button" 
                    className="inline-block w-44 rounded-lg py-2 px-8 bg-gray-200 hover:bg-gray-300 font-medium"
                    onClick={() => setShow(false)}
                >
                    Cancel
                </button>
                <button type="submit" 
                    className="inline-block w-44 rounded-lg py-2 px-8 bg-primary hover:bg-primary-600 text-white font-medium"
                    onClick={(e) => {
                        saveChanges(e)
                        setShow(false)
                    }}
                >
                    Save
                </button>
            </section>
        </>
    )
}

export default CustomFieldsListForm
