import React from "react";
import { FormField, HiddenField, ToggleField } from "@shared/form_fields";
import { labelize } from "@shared/utils";

export default function LookupTableEntryForm({
  lookup_table_id,
  fields,
  lookup_table_lookup_field,
}) {
  
  return (
    <>
      {lookup_table_id && (
        <HiddenField field="lookup_table_id" value={lookup_table_id} />
      )}

      {lookup_table_lookup_field && (
        <HiddenField
          field="lookup_table_lookup_field"
          value={lookup_table_lookup_field}
        />
      )}

      {fields
        ?.sort(function (x, y) {
          return x.name == lookup_table_lookup_field
            ? -1
            : y.name == lookup_table_lookup_field
              ? 1
              : 0;
        })
        .map((field, i) => {
          if (field.field_type !== "Boolean") {
            return (
              <>
                <FormField
                  key={field.name + "-" + i}
                  field={`fields.${field.name}`}
                  label={labelize(
                    `${field.name}${lookup_table_lookup_field === field.name ? "*" : ""}`
                  )}
                />
              </>
            );
          } else {
            return (
              <ToggleField
                key={field.name + "-" + i}
                field={`fields.${field.name}`}
                label={labelize(field.name)}
                returnBoolean={true}
              />
            );
          }
        })}
    </>
  );
}
